.header {
  display: flex;
  align-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 56px;
  border-bottom: 2px solid $darkest;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  padding: 0 1rem 0 1.5rem;
  .app--dark & {
    border-color: $lightest;
  }
}

.header__settings {
  flex: 1 1 auto;
  height: 38px;
}
.header__widgets {
  height: 38px;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
}

//Hamburger
.icon-humburger {
  height: 38px;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: pointer;
  .app--dark & path {
    fill: $lightest;
  }
  svg {
    transform: scale(0.85);
    transform-origin: center left;
  }
}

// Current Pick
.current-pick {
  border-radius: 4px;
  display: grid;
  height: 38px;
  grid-template-columns: 53px 20px;
  grid-template-rows: 1fr 1fr;
  margin-left: 0.25rem;
}
.current-pick__pick-container {
  background: $lightest;
  height: 38px;
  border-radius: 4px 0 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  .app--dark & {
    background: $darkest;
    border-top: 2px solid $primary;
    border-left: 2px solid $primary;
    border-bottom: 2px solid $primary;
  }
}
.current-pick__pick {
  padding-top: 2px;
  color: $primary;
  @extend h4;
  font-weight: bold;
  text-align: center;
  .app--dark & {
    color: $lightest;
  }
}
.current-pick__adjuster {
  background: $primary;
  color: $lightest;
  border: none;
  grid-column: 2 / 3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  &.current-pick__adjuster-up {
    grid-row: 1 / 2;
    border-radius: 0 4px 0 0;
  }
  &.current-pick__adjuster-down {
    grid-row: 2 / 3;
    border-radius: 0 0 4px 0;
  }
}

//Player Search
.player-search__container {
  height: 38px;
  position: relative;
  margin: 0 0.25rem;
}
.player-search {
  height: 100%;
  width: 230px;
  background: none;
  border: 2px solid $lightest;
  border-radius: 4px;
  z-index: 3;
  color: $secondary;
  padding: 0 28px 0 6px;
  position: relative;
  font-weight: 600;
  transition: width 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  @extend .small1;
  .app--dark & {
    border-color: $primary;
  }
  &[value="Search Player"],
  &[value=""] {
    width: 38px;
  }
  &:hover {
    width: 230px;
  }
  &:focus {
    width: 230px;
    color: $primary;
    outline: none;
    .app--dark & {
      color: $lightest;
    }
  }
  &::placeholder {
    font-family: $font-family;
    font-weight: 450;
    color: $secondary;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  }
  &:hover::placeholder,
  &:focus::placeholder {
    opacity: 1;
  }
}
.icon-search {
  position: absolute;
  top: calc(50% - 6px);
  right: 13px;
  display: none;
  &--active {
    display: block;
  }
  .app--dark & path {
    fill: $lightest;
  }
}
.icon-close-search {
  position: absolute;
  top: calc(50% - 10px);
  right: 9px;
  padding: 5px;
  width: 20px;
  height: 20px;
  z-index: 5;
  border: 0;
  background: none;
  display: none;
  cursor: pointer;
  &--active {
    display: block;
  }
  .app--dark & path {
    fill: $lightest;
  }
}

//User Picks
.user-picks__container {
  height: 38px;
  background: $lightest;
  border-radius: 4px;
  margin: 0 0.25rem;
  padding: 0.5rem;
  font-weight: bold;
  @extend .small2;
  .app--dark & {
    background: none;
    border: 2px solid $primary;
    color: $lightest;
  }
  ul {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .user-pick__list-item {
    display: flex;
    align-items: center;
    transition: color 0.4s cubic-bezier(0, 0, 0, 1);
    path {
      transition: fill 0.4s cubic-bezier(0, 0, 0, 1);
    }
    &--past {
      color: $secondary;
      path {
        fill: $secondary;
      }
    }
    .app--dark & {
      path {
        fill: $lightest;
      }
      &--past {
        path {
          fill: $secondary;
        }
      }
    }
    &--error {
      color: $negative;
      transition: color 1s ease;
      .app--dark & {
        color: $highlight;
      }
    }
    &--current {
      color: $negative;
      transition: color 1s ease;
      .app--dark & {
        color: $highlight;
      }
    }
    &:last-of-type svg {
      display: none;
    }
  }
  .icon-right-arrow {
    transform: scale(1.5);
    padding: 0 3px 1px;
    height: 4px;
    width: 10px;
  }
  .user-pick {
    padding: 0 1px 0 0;
  }
}
