.settings {
  &:not(.settings__transitioning):not(.settings--open) {
    pointer-events: none;
  }
  &.settings__transitioning {
    pointer-events: none;
  }
  .settings__container {
    padding: 2rem 1.5rem 2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 400px;
    background: $light;
    height: 100vh;
    z-index: 50;
    box-shadow: 2px 2px 4px rgba(71, 93, 91, 0.15);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    transform: translateX(-100%);
    transition: background 0.2s cubic-bezier(0.65, 0.05, 0.36, 1),
      transform 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
    .app--dark & {
      background: $darkest;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    }
  }
  .settings__overlay {
    background: $darkest;
    z-index: 49;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
    .app--dark & {
      background: #000000;
    }
  }
  &--open {
    .settings__container {
      transform: none;
    }
    .settings__overlay {
      opacity: 0.4;
      .app--dark & {
        opacity: 0.8;
      }
    }
  }
  .close-settings {
    background: none;
    border: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 0;
    position: absolute;
    top: 0.5rem;
    left: calc(1.5rem - 4px);
    .icon-close {
      transform: scale(2);
      .app--dark & path {
        fill: $lightest;
      }
    }
  }
  .settings__title {
    margin-top: 2.5rem;
    border-bottom: 2px solid $primary;
    font-weight: 800;
    .app--dark & {
      border-color: $lightest;
    }
  }
  .app-settings h2 {
    margin-bottom: 2rem;
  }
  .settings__category-container {
    display: grid;
    gap: 0.5rem;
    margin: 0.5rem 0;
    &--full {
      grid-template-columns: 1fr;
      margin: 1rem 0;
    }
    &--half {
      grid-template-columns: repeat(2, 1fr);
    }
    &--third {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .settings__category-title {
    font-size: 1rem;
    margin-top: 1.5rem;
    grid-column: 1 / 4;
    margin-bottom: 0.5rem;
  }
  .settings__scoring-title {
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    grid-column: 1 / 4;
    font-weight: 500;
    @extend .small1;
  }
  .settings__category-title + .settings__scoring-title {
    margin-top: 0;
  }
  .input-containter {
    width: 100%;
    position: relative;
    background: $lightest;
    border-radius: 4px;
    border: 2px solid $lightest;
    display: flex;
    align-items: center;
    label {
      height: calc(2rem - 2px);
      padding: 3px 0 2px 0.5rem;
      flex: 1 1 auto;
      font-weight: bold;
      display: flex;
      align-items: center;
      @extend .small2;
      &.text--number {
        font-weight: 600;
      }
    }
    input {
      padding: 2px 0.5rem 2px 0;
      height: calc(2rem - 2px);
      background: none;
      border: none;
      color: $primary;
      outline: none;
      width: 30px;
      text-align: right;
      flex: 0 0 auto;
      font-weight: 450;
      @extend .small2;
    }
  }
  button.button {
    height: 2rem;
    font-weight: bold;
    border: 2px solid $primary;
    border-radius: 4px;
    background: $lightest;
    padding: 0.25rem 0.5rem;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0, 0, 0, 1);
    &:hover {
      background: $positive;
    }
    .app--dark & {
      border-color: $lightest;
      background: $lightest;
      &:hover {
        border-color: $highlight;
        background: $highlight;
      }
    }
    &--positive {
      border: 2px solid $positive;
      color: $positive;
      &:hover {
        background: $positive;
        color: $primary;
      }
      .app--dark & {
        border-color: $positive;
        background: $positive;
        color: $primary;
      }
    }
    &--negative {
      border: 2px solid $negative;
      color: $negative;
      &:hover {
        background: $negative;
        color: $primary;
      }
      .app--dark & {
        border-color: $negative;
        background: $negative;
        color: $primary;
      }
    }
  }
  .switch__container {
    display: flex;
    align-items: center;
    &--color-mode {
      margin-bottom: 0.5rem;
    }
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 28px;
    margin: 0 1rem 0 0;
    input {
      opacity: 0;
      width: 0;
      height: 0;
      &:checked + .slider {
        background-color: $positive;
        .app--dark & {
          background-color: $highlight;
        }
      }
      &:checked + .slider:before {
        -webkit-transform: translateX(28px);
        -ms-transform: translateX(28px);
        transform: translateX(28px);
      }
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $lightest;
      transition: 0.4s;
      &--round {
        border-radius: 14px;
        &:before {
          border-radius: 50%;
        }
      }
    }
    .slider:before {
      position: absolute;
      content: '';
      height: 22px;
      width: 22px;
      left: 3px;
      bottom: 3px;
      background-color: $primary;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  .switch__text {
    margin: 0;
    @extend .small1;
    font-weight: bold;
    &--color-mode {
      font-size: 1rem;
    }
    &:first-letter {
      text-transform: capitalize;
    }
    .app--dark & {
      color: $lightest;
    }
  }
  .stats-timestamp {
    font-size: 0.75rem;
    margin-top: 1rem;
    font-weight: 500;
    span {
      font-weight: 400;
    }
    .app--dark & {
      color: $lightest;
    }
  }
}
