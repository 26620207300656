//Overall List
.overall-list {
  border-right: 2px solid $darkest;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .app--dark & {
    border-color: $lightest;
  }
  .player-list {
    padding: 0 1.5rem 1rem;
    overflow-y: scroll;
  }
  .list-head {
    margin: 1rem 1.5rem 0;
  }
  .player-card__position {
    width: 26px;
    text-align: center;
  }
  .player-card__team {
    flex: 1 1 auto;
  }
  .player-card--DST .player-card__name {
    flex: 1 1 auto;
  }
}

//Positional List
.positional-list__container {
  padding: 0 0.5rem;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  overflow-y: hidden;
  display: flex;
  .positional-list {
    display: flex;
    flex-direction: column;
  }
  .player-list {
    overflow-y: scroll;
    margin: 0;
  }
  .player-card__team {
    flex: 1 1 auto;
    margin-right: 1rem;
  }
  .positional-list__dst .player-card__name {
    flex: 1 1 auto;
    margin-right: 1rem;
  }
}

//List Heads
.list-head {
  margin: 1rem 1rem 0;
  border-bottom: 2px solid $darkest;
  flex: 0 0 auto;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
  .app--dark & {
    border-color: $lightest;
  }
  .list-heading {
    margin-right: 1rem;
    font-weight: 800;
  }
  .stats-dropdrown__container {
    .icon-down-caret {
      position: absolute;
      right: 0;
      top: 53%;
      .app--dark & path {
        fill: $lightest;
      }
    }
  }
  select.stats-dropdrown::-ms-expand {
    display: none;
  }
  .stats-dropdrown {
    appearance: none;
    background: none;
    border: none;
    font-weight: bold;
    color: $primary;
    padding-right: 15px;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    z-index: 2;
    position: relative;
    outline: none;
    font-weight: 600;
    .app--dark & {
      color: $lightest;
    }
  }
  .player-filter {
    border: none;
    display: flex;
    padding: 0 0 0 40px;
    margin: 0;
    position: relative;
    &:hover .player-filter__input-container:nth-child(n),
    &:hover legend:nth-child(n) {
      transform: translateX(0);
    }
    .player-filter__input-container {
      position: relative;
      margin-right: 2px;
      // margin-right: -10px;
      height: 20px;
      // transition: margin-right 300ms ease-out;
      &:nth-child(6) {
        transform: none;
      }
      &:nth-child(5) {
        transform: translateX(10px);
      }
      &:nth-child(4) {
        transform: translateX(20px);
      }
      &:nth-child(3) {
        transform: translateX(30px);
      }
      &:nth-child(2) {
        transform: translateX(40px);
      }
      &:last-of-type {
        margin-right: 0;
      }
      &:hover {
        z-index: 6;
      }
    }
    legend {
      position: absolute;
      left: 0;
      font-weight: 600;
      padding-top: 2px;
      transform: translateX(40px);
      @extend .small1;
      .app--dark & {
        color: $lightest;
      }
    }
    .player-filter__input-container,
    legend {
      transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    input[type="checkbox"] {
      visibility: hidden;
      &:checked + label {
        background-color: $primary;
        .app--dark & {
          background-color: $lightest;
        }
      }
    }
    label {
      background-color: $light;
      border: 2px solid $primary;
      border-radius: 50%;
      cursor: pointer;
      height: 20px;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
      transition: transform 0.3s cubic-bezier(0, 0, 0, 1), box-shadow 0.3s cubic-bezier(0, 0, 0, 1);
      .app--dark & {
        background-color: $darkest;
        border-color: $lightest;
      }
      &:hover {
        transform: scale(1.1);
        box-shadow: 0 0 3px 1px rgba(71, 93, 91, 0.2);
        .app--dark & {
          box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.5);
        }
      }
    }
    #filter-drafted:checked + label {
      background-color: $secondary;
    }
    #filter-starred:checked + label {
      background-color: $starred;
    }
    #filter-favorited:checked + label {
      background-color: $favorited;
    }
    #filter-hated:checked + label {
      background-color: $hated;
    }
  }
}

//Lists
.player-list {
  flex: 1 1 auto;
  height: 100%;
  padding: 0 1rem 1rem;
}
.positional-list:last-of-type .player-list {
  padding-right: 1rem;
}
.player-list__title {
  font-weight: 700;
  margin: 1.5rem 0 0.5rem;
  height: 26px;
  font-size: 1rem;
  span {
    font-weight: 600;
  }
}
.player-list__list {
  padding: 0;
  margin: 0.5rem 0 1rem;
}

//Player Card
.player-card {
  min-width: 400px;
  margin: 0.75rem 0;
  padding: 0.35rem 0.15rem;
  overflow: hidden;
  border-radius: 4px;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  background: $lightest;
  width: 100%;
  height: 38px;
  border: 2px solid $lightest;
  transition: all 0.2s cubic-bezier(0, 0, 0, 1);
  &__rank {
    align-self: center;
    background: $primary;
    position: relative;
    padding: 1px 2px 0;
    margin: 0 0.25rem;
    width: 32px;
    text-align: center;
    color: $lightest;
    font-weight: 625;
    flex: 0 0 auto;
    border-radius: 8px;
    @extend .small2;
  }
  &__name {
    margin: 0 0.25rem;
    font-weight: 650;
    font-size: 1rem;
    white-space: nowrap;
  }
  &__position {
    margin: 0 0.5rem;
    text-transform: uppercase;
    font-weight: 500;
    @extend .small1;
  }
  &__team {
    text-transform: uppercase;
    margin: 0 0.25rem;
    font-weight: 500;
    letter-spacing: 0.4px;
    @extend .small2;
  }
  &__pnts {
    margin: 0 0.5rem;
    font-weight: 650;
    @extend .small1;
  }
}

//Stats Table
.stats-table {
  align-self: center;
  caption {
    position: static;
  }
  td {
    font-weight: 500;
    @extend .small2;
  }
  th {
    font-weight: 650;
    @extend .small3;
  }
  td,
  th {
    text-align: center;
    line-height: 1.1;
    padding: 0 6px;
  }
  &.stats-table--hidden {
    display: none;
  }
  &--pass {
    min-width: 225px;
  }
  &--rush {
    min-width: 155px;
  }
  &--rec {
    min-width: 155px;
  }
  th.stats-table__total-points {
    font-weight: 800;
  }
  td.stats-table__total-points {
    font-weight: 675;
  }
}

//Player Hover
.player-card:hover:not(.player-card--drafted):not(.player-card--hate):not(.player-card--favorite):not(.player-card--star) {
  border-color: $primary;
  .app--dark & {
    border-color: $secondary;
    background: $secondary;
    .player-card__rank {
      color: $secondary;
    }
  }
}
.player-card.player-card--drafted:hover {
  .app--dark & {
    border-color: $secondary;
    background: $secondary;
    .player-card__rank {
      color: $secondary;
    }
  }
  .player-card__button path {
    .app--dark & {
      fill: $primary;
      opacity: 0.25;
    }
  }
}

//Player Status
.player-card__sentiment {
  padding-top: 2px;
  align-self: center;
  display: flex;
  align-items: center;
  margin: 0 0.25rem;
  .player-card__button {
    height: 16px;
    cursor: pointer;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    margin: 0 3px;
    &:first-of-type {
      margin: 0 3px 0 0;
    }
    &:last-of-type {
      margin: 0 0 0 3px;
    }
  }
  .player-card__button svg {
    height: 15px;
    width: 16px;
  }
}
.player-card button svg path {
  fill: $primary;
}

// Starred
.player-card--starred {
  background: $starred;
  border: 2px solid $starred;
  .player-card__rank {
    color: $starred;
  }
  .player-card__button--favorite svg path,
  .player-card__button--hate svg path {
    opacity: 0.25;
  }
}

// Favorited
.player-card--favorited {
  background: $favorited;
  border: 2px solid $favorited;
  .player-card__rank {
    color: $favorited;
  }
  .player-card__button--star svg,
  .player-card__button--hate svg {
    opacity: 0.25;
  }
}

// Hated
.player-card--hated {
  background: $hated;
  border: 2px solid $hated;
  .player-card__rank {
    color: $hated;
  }
  .player-card__button--star svg,
  .player-card__button--favorite svg {
    opacity: 0.25;
  }
}

// Drafted
.player-card--drafted {
  background: $light;
  border-color: $secondary;
  color: $secondary;
  .app--dark & {
    border-color: $primary;
    background: none;
  }
  .player-card__rank {
    color: $light;
    background: $secondary;
    .app--dark & {
      color: $darkest;
    }
  }
  .player-card__button--star svg path,
  .player-card__button--favorite svg path,
  .player-card__button--hate svg path {
    fill: $secondary;
  }
  &:hover {
    border-color: $primary;
    color: $primary;
    .player-card__rank {
      background: $primary;
    }
  }
}
