// Base Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary;
  margin: 0;
  .app--dark & {
    color: $lightest;
  }
}
h1 {
  font-size: 2.074rem;
}
h2 {
  font-size: 1.728rem;
}
h3 {
  font-size: 1.44rem;
}
h4 {
  font-size: 1.2rem;
}
small,
.small1 {
  font-size: 0.833rem;
}
.small2 {
  font-size: 0.694rem;
}
.small3 {
  font-size: 0.579rem;
}
.text--number {
  font-family: $numbers-font-famiy;
}
input {
  font-family: $font-family;
  font-size: 1rem;
}
